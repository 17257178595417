import React from 'react';

import { Articles } from '../components/articles';
import { Bio } from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <aside>
      <Bio />
    </aside>
    <main>
      <Articles />
    </main>
  </Layout>
);

export default IndexPage;
