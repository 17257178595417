import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

import { colors } from '../styles/base';

const ArticleHeaderLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const Articles = () => {
  const {
    allMdx: { edges: posts },
  } = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              createdDate
              path
              title
              summary
            }
          }
        }
      }
    }
  `);

  const sortedPostsByCreatedDate = posts.sort(
    (
      {
        node: {
          frontmatter: { createdDate: a },
        },
      },
      {
        node: {
          frontmatter: { createdDate: b },
        },
      }
    ) => new Date(b) - new Date(a)
  );

  return (
    <>
      {sortedPostsByCreatedDate.map(
        ({
          node: {
            id,
            timeToRead,
            frontmatter: { title, path, createdDate, summary },
          },
        }) => {
          const timeToReadStr = `${timeToRead} minute read`;
          const cups = Math.round(timeToRead / 5);
          const emojis = new Array(cups || 1).fill('☕️').join('');

          return (
            <article key={id} style={{ marginBottom: '3.5rem' }}>
              <header>
                <h1 style={{ marginBottom: '0.5rem' }}>
                  <ArticleHeaderLink to={path}>{title}</ArticleHeaderLink>
                </h1>
                <small>
                  {createdDate}
                  <span style={{ color: colors.SUN_FLOWER, margin: '0 .5rem' }}>
                    ⦿
                  </span>
                  {timeToReadStr}
                  <span style={{ marginLeft: '.5rem' }}>{emojis}</span>
                </small>
                <p style={{ marginTop: 0 }}>{summary}</p>
              </header>
            </article>
          );
        }
      )}
    </>
  );
};
